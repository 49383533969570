'use client';

import { motion } from "motion/react";
import dynamic from 'next/dynamic';
import Auth from '@/components/Auth';
import Button from '@/components/ui/Button';
import CopyToClipboard from '@/components/ui/CopyToClipboard';
import Image from '@/components/ui/Image';
import { MusixmatchProLogo } from '@/components/ui/Logo';
import { LogoFullPlain as MatchfyLogo } from '@/components/ui/Logo/Logo';
import Separator from '@/components/ui/Separator';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from "@/utils";
const BeamBackground = dynamic(() => import('./BeamBackground'), {
  ssr: false
});
export default function HeroMusixmatch({
  className,
  couponCode,
  couponPercentage = 30
}) {
  const t = useTranslations();
  return <div className={cn("relative w-full overflow-hidden", className)} data-sentry-component="HeroMusixmatch" data-sentry-source-file="HeroMusixmatch.jsx">
      <BeamBackground overlayClasses="overlay-musixmatch" data-sentry-element="BeamBackground" data-sentry-source-file="HeroMusixmatch.jsx" />

      <div className="relative z-10 flex items-center justify-center w-full h-full">
        <div className="container">
          <div className="flex flex-col gap-8 md:grid lg:grid-cols-2">
            <div className="z-20 flex flex-col items-start justify-center gap-6 pt-32 pb-24 text-left md:pt-40">
              <motion.h1 className="m-0 text-4xl font-semibold text-white sm:text-5xl md:text-6xl text-balance" initial={{
              opacity: 0,
              y: 20
            }} animate={{
              opacity: 1,
              y: 0
            }} transition={{
              duration: 0.8
            }} data-sentry-element="unknown" data-sentry-source-file="HeroMusixmatch.jsx">
                {t('musixmatch.hero.title')}
              </motion.h1>

              <motion.p className="m-0 text-lg tracking-tighter text-white md:text-2xl opacity-90 text-balance" initial={{
              opacity: 0,
              y: 20
            }} animate={{
              opacity: 1,
              y: 0
            }} transition={{
              duration: 0.8
            }} data-sentry-element="unknown" data-sentry-source-file="HeroMusixmatch.jsx">
                {t('musixmatch.hero.description', {
                percentage: couponPercentage,
                shouldParse: true
              })}
              </motion.p>

              <Auth login loginProps={{
              className: 'button-cta mt-2',
              variant: 'white',
              icon: 'ArrowRight'
            }} data-sentry-element="Auth" data-sentry-source-file="HeroMusixmatch.jsx">
                <Button href={ROUTES.MATCH} variant="white" className="mt-2 button-cta" icon="ArrowRight" label={t('home.main.start_now')} data-sentry-element="Button" data-sentry-source-file="HeroMusixmatch.jsx" />
              </Auth>

              <div className="flex flex-wrap items-center gap-4">
                <CopyToClipboard variant="white-light" className="button-cta" iconPosition="right" label={couponCode} text={couponCode} data-sentry-element="CopyToClipboard" data-sentry-source-file="HeroMusixmatch.jsx" />

                <p className="text-sm text-white opacity-90 text-balance max-w-[48ch]">
                  {t('musixmatch.hero.coupon_description', {
                  percentage: couponPercentage,
                  shouldParse: true
                })}
                </p>
              </div>

              <Separator className="w-full mt-6 mb-3 bg-white opacity-50" data-sentry-element="Separator" data-sentry-source-file="HeroMusixmatch.jsx" />

              <motion.div className="flex flex-col items-start gap-5 md:items-center md:flex-row" initial={{
              opacity: 0,
              y: 20
            }} animate={{
              opacity: 1,
              y: 0
            }} transition={{
              duration: 0.8
            }} data-sentry-element="unknown" data-sentry-source-file="HeroMusixmatch.jsx">
                <MusixmatchProLogo className="w-auto h-10 text-white" data-sentry-element="MusixmatchProLogo" data-sentry-source-file="HeroMusixmatch.jsx" />
                <span className="hidden text-white opacity-70 md:inline">X</span>
                <MatchfyLogo className="w-auto h-10 text-white" data-sentry-element="MatchfyLogo" data-sentry-source-file="HeroMusixmatch.jsx" />
              </motion.div>
            </div>

            <div className="absolute inset-0 z-10 overlay overlay-musixmatch" />

            <div className="relative items-end justify-center hidden z-5 lg:flex">
              <Image src="/images/landing/matchfy_hero_girl.png" alt="Musixmatch" data-sentry-element="Image" data-sentry-source-file="HeroMusixmatch.jsx" />
            </div>
          </div>
        </div>
      </div>
    </div>;
}