import { cn } from '@/utils';
const DeviceMockup = ({
  className,
  image,
  alt = '',
  children
}) => {
  const classes = cn({
    'device-mockup': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="DeviceMockup" data-sentry-source-file="DeviceMockup.jsx">
      <div className="pin pin-left-1"></div>
      <div className="pin pin-left-2"></div>
      <div className="pin pin-left-3"></div>
      <div className="pin pin-right"></div>

      <div className="device-mockup-inner">
        {image && <img src={image} alt={alt} />}

        {children && children}
      </div>
    </div>;
};
export default DeviceMockup;