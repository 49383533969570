'use client';

import Link from 'next/link';
import React from 'react';
import { COLLABS } from '@/data';
import { useTranslations } from '@/hooks';
export const Collabs = ({
  show = true,
  className
}) => {
  const t = useTranslations();
  const activeCollabs = COLLABS.filter(collab => collab?.show);
  if (!show || activeCollabs.length === 0) return null;
  return <div className={className} data-sentry-component="Collabs" data-sentry-source-file="Collabs.jsx">
      <span className="block mb-3 text-base font-medium text-current opacity-70">
        {t('collabs.title')}
      </span>

      <div className="flex flex-wrap items-center justify-start gap-x-10 gap-y-4">
        {activeCollabs.map(collab => <Link href={collab.cta} target="_blank" key={collab.id} className="flex items-center justify-center h-5 gap-2 text-current opacity-50 hover:opacity-90">
            {React.cloneElement(collab.logo, {
          className: 'h-full'
        })}
          </Link>)}
      </div>
    </div>;
};
export default Collabs;