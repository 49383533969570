'use client';

import { motion, AnimatePresence } from "motion/react";
import React from 'react';
import Badge from '@/components/ui/Badge';
import Card from '@/components/ui/Card';
import Counter from '@/components/ui/Counter';
import Icon from '@/components/ui/Icon';
import Video from '@/components/ui/Video';
import { COUNTERS, COUNTER_DURATION, DAILY_UPLOADED_SONGS, PLAYLIST_COVER_DATA } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const Solution = ({
  className
}) => {
  const t = useTranslations();
  const Problem = () => {
    const playlists = PLAYLIST_COVER_DATA.map((playlist, idx) => {
      return {
        title: playlist.title,
        image: `/images/covers/cover_${++idx}.jpg`
      };
    });
    const activeIndex = Math.floor(Math.random() * playlists.length);
    const containerVariants = {
      hidden: {
        opacity: 0
      },
      show: {
        opacity: 1,
        transition: {
          staggerChildren: 0.04
        }
      }
    };
    const itemVariants = {
      hidden: {
        opacity: 0
      },
      show: {
        opacity: 1
      }
    };
    return <Card data-sentry-element="Card" data-sentry-component="Problem" data-sentry-source-file="Solution.jsx">
        <div className="grid items-center md:grid-cols-2 md:gap-8">
          <div className="p-8">
            <h3 className="inline-flex gap-3 mb-3 text-5xl font-bold text-primary md:text-8xl">
              <Counter interval={4000} start={0} end={DAILY_UPLOADED_SONGS} data-sentry-element="Counter" data-sentry-source-file="Solution.jsx" />
              <span>+</span>
            </h3>

            <h4>{t('home.problem.title')}</h4>

            <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="Solution.jsx">
              <motion.div variants={containerVariants} initial="hidden" animate="show" className="solution-cover-grid-wrapper" data-sentry-element="unknown" data-sentry-source-file="Solution.jsx">
                <div className="solution-cover-grid">
                  {playlists.map((playlist, idx) => <motion.div key={idx} className={cn({
                  cell: true,
                  active: idx === activeIndex
                })} variants={itemVariants}>
                      <img src={playlist.image} alt={playlist.title} />
                    </motion.div>)}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          <div className="p-8">
            <p className="text-xl">
              {t('home.problem.body.1', {
              shouldParse: true
            })}
            </p>

            <h4 className="my-6">
              {t('home.problem.body.2', {
              shouldParse: true
            })}
            </h4>

            <p className="text-xl">
              {t('home.problem.body.3', {
              shouldParse: true
            })}
            </p>
          </div>
        </div>
      </Card>;
  };
  const Solution = () => {
    const SolutionCard = ({
      icon,
      color,
      title,
      text,
      footer,
      video,
      className
    }) => {
      const cardClasses = cn({
        card: true,
        'solution-card': true,
        'solution-video': video,
        [`card-${color}`]: color,
        [className]: className
      });
      return <div className={cardClasses} data-sentry-component="SolutionCard" data-sentry-source-file="Solution.jsx">
          <div className="flex flex-col justify-between h-full">
            {icon && <div className="card-icon">
                <Icon name={icon} />
              </div>}

            <div className="flex flex-col gap-4 mt-auto">
              <div className="card-content">
                <div className="flex flex-col gap-1">
                  {title && <h3 className="m-0">{title}</h3>}
                  {text && <p>{text}</p>}
                </div>
              </div>

              {footer && <div className="card-footer">{footer}</div>}
            </div>
          </div>

          {video && <Video muted autoPlay loop playsinline={true} controls={false} src={video} />}
        </div>;
    };
    const SolutionGrid = () => {
      return <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-6" data-sentry-component="SolutionGrid" data-sentry-source-file="Solution.jsx">
          <SolutionCard icon="Music" className="solution-card sm:col-span-2 md:col-span-6 card-primary border-b-1" title={t('home.solution.match.title')} text={t('home.solution.match.body')} video="/video/matchfy_match.mp4" footer={<div className="flex flex-wrap justify-start gap-2">
                <Badge variant="primary-light" icon="ArrowRight" className="capitalize" label={t('home.solution.submissions.counter')} value={<Counter start={0} duration={COUNTER_DURATION} end={COUNTERS.SUBMISSIONS} />} />

                <Badge variant="primary-light" icon="UserRound" className="capitalize" label={t('home.solution.artists.counter')} value={<Counter start={0} duration={COUNTER_DURATION} end={COUNTERS.ARTISTS} />} />

                <Badge variant="primary-light" icon="Music" className="capitalize" label={t('home.solution.playlists.counter')} value={<Counter start={0} duration={COUNTER_DURATION} end={COUNTERS.PLAYLISTS} />} />
              </div>} data-sentry-element="SolutionCard" data-sentry-source-file="Solution.jsx" />

          <SolutionCard icon="FileChartLine" className="solution-card card-secondary md:col-span-3" title={t('home.solution.analysis.title')} text={t('home.solution.analysis.body')} footer={<div className="flex flex-wrap justify-start gap-2">
                <Badge variant="secondary-light" icon="ChartLine" className="capitalize" label={t('home.solution.analysis.counter')} value={<Counter start={0} duration={COUNTER_DURATION} end={COUNTERS.ANALYSIS} />} />
              </div>} data-sentry-element="SolutionCard" data-sentry-source-file="Solution.jsx" />

          <SolutionCard icon="Rocket" className="solution-card card-red md:col-span-3" title={t('home.solution.accelerate.title')} text={t('home.solution.accelerate.body')} footer={<div className="flex flex-wrap justify-start gap-2">
                <Badge variant="red-light" icon="Rocket" className="capitalize" label={t('home.solution.accelerate.counter')} value={<Counter start={0} duration={COUNTER_DURATION} end={COUNTERS.ACCELERATE} />} />
              </div>} data-sentry-element="SolutionCard" data-sentry-source-file="Solution.jsx" />

          <SolutionCard icon="Gem" className="solution-card card-gold md:col-span-2" title={t('home.solution.vip_plays.title')} text={t('home.solution.vip_plays.body')} footer={<div className="flex flex-wrap justify-start gap-2">
                <Badge variant="gold-light" icon="Play" className="capitalize" label={t('home.solution.vip_plays.counter')} value={<Counter start={0} duration={COUNTER_DURATION} end={COUNTERS.PLAYS} />} />
              </div>} data-sentry-element="SolutionCard" data-sentry-source-file="Solution.jsx" />

          <SolutionCard icon="Trophy" className="solution-card card-orange md:col-span-2" title={t('home.solution.rank.title')} text={t('home.solution.rank.body')} footer={<div className="flex flex-wrap justify-start gap-2">
                <Badge variant="orange-light" icon="Award" className="capitalize" label={t('home.solution.rank.counter')} value={<Counter start={0} duration={COUNTER_DURATION} end={COUNTERS.CURATORS} />} />
              </div>} data-sentry-element="SolutionCard" data-sentry-source-file="Solution.jsx" />

          <SolutionCard icon="ShieldCheck" className="solution-card card-green md:col-span-2" title={t('home.solution.check.title')} text={t('home.solution.check.body')} footer={<div className="flex flex-wrap justify-start gap-2">
                <Badge variant="green-light" icon="Check" className="capitalize" label={t('home.solution.check.counter')} value={<Counter start={0} duration={COUNTER_DURATION} end={COUNTERS.FAKE_PLAYLISTS} />} />
              </div>} data-sentry-element="SolutionCard" data-sentry-source-file="Solution.jsx" />
        </div>;
    };
    return <>
        <Card className="bg-transparent" data-sentry-element="Card" data-sentry-source-file="Solution.jsx">
          <div className="flex flex-col">
            <div className="p-4 md:p-8">
              <div className="grid items-center gap-4 md:grid-cols-3 md:gap-8">
                <h3 className="inline-flex gap-3 mb-3 text-6xl font-bold text-gradient md:text-7xl">
                  {t('home.solution.intro.title')}
                </h3>

                <div className="flex flex-col gap-3 md:col-span-2">
                  <p className="text-xl">
                    {t('home.solution.intro.body.1', {
                    shouldParse: true
                  })}
                  </p>

                  <p className="text-xl">
                    {t('home.solution.intro.body.2', {
                    shouldParse: true
                  })}
                  </p>

                  <p className="text-xl">
                    {t('home.solution.intro.body.3', {
                    shouldParse: true
                  })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </>;
  };
  const classes = cn({
    section: true,
    container: true,
    [className]: className
  });
  return <div id="solution" className={classes} data-sentry-component="Solution" data-sentry-source-file="Solution.jsx">
      <Problem data-sentry-element="Problem" data-sentry-source-file="Solution.jsx" />
    </div>;
};
export default Solution;