'use client';

import { matchfyApi } from '@/api';
import { CuratorsGrid } from '@/components/Curator';
import Button from '@/components/ui/Button';
import { ROUTES, USER_TYPES } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const Curators = ({
  className
}) => {
  const t = useTranslations();
  const classes = cn({
    curators: true,
    section: true,
    'container': true,
    [className]: className
  });
  return <section id="curators" className={classes} data-sentry-component="Curators" data-sentry-source-file="Curators.jsx">
      <CuratorsGrid queryKey="proCurators" type={USER_TYPES.PRO} fetchFn={matchfyApi.getCurators} title={t('curator.pro.title')} subtitle={t('curator.pro.subtitle')} limit={4} data-sentry-element="CuratorsGrid" data-sentry-source-file="Curators.jsx" />

      <CuratorsGrid queryKey="starCurators" type={USER_TYPES.STAR} fetchFn={matchfyApi.getCurators} title={t('curator.star.title')} subtitle={t('curator.star.subtitle')} limit={4} data-sentry-element="CuratorsGrid" data-sentry-source-file="Curators.jsx" />

      <CuratorsGrid queryKey="featuredCurators" type={USER_TYPES.FEATURED} fetchFn={matchfyApi.getCurators} title={t('curator.featured.title')} subtitle={t('curator.featured.subtitle')} limit={4} extraProps={{
      featured: true
    }} data-sentry-element="CuratorsGrid" data-sentry-source-file="Curators.jsx" />

      <div className="w-full mt-8">
        <Button icon="ArrowRight" href={ROUTES.COMMUNITY} label={t('navigation.community')} variant="light" data-sentry-element="Button" data-sentry-source-file="Curators.jsx" />
      </div>
    </section>;
};
export default Curators;