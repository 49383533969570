import React from 'react';
import Badge from '@/components/ui/Badge';
import SectionTitle from '@/components/ui/SectionTitle';
import { useTranslations } from '@/hooks';
const Intro = () => {
  const t = useTranslations();
  return <section id="intro" className="container section" data-sentry-component="Intro" data-sentry-source-file="Intro.jsx">
      <SectionTitle tag="h3" title={t('home.intro.title')} subtitle={t('home.intro.subtitle')} badge={<Badge variant="blue-light" label="AI" icon="Sparkles" />} data-sentry-element="SectionTitle" data-sentry-source-file="Intro.jsx" />
    </section>;
};
export default Intro;