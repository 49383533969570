'use client';

import dayjs from 'dayjs';
import React from 'react';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { CountdownDate } from '@/components/ui/Countdown';
import Icon from '@/components/ui/Icon';
import { ROUTES } from '@/data';
import { useLocale, useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { cn, getLongDateFormat } from '@/utils';
const PROMO_DATE = new Date('2025-03-10');
const SHOW_COUNTDOWN = true;
const PromoCard = ({
  enable = true,
  couponCode,
  themeColor = 'primary',
  className
}) => {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const classes = cn({
    partners: true,
    section: true,
    'container max-w-screen-md': true,
    [className]: className
  });
  const handleCouponCopy = () => {
    navigator.clipboard.writeText(couponCode);
    toast('success', t('feedback.success.copied'));
  };
  const Ticket = () => {
    return <div className="z-10 transition-all duration-300 cursor-pointer ticket hover:scale-95" onClick={handleCouponCopy} data-sentry-component="Ticket" data-sentry-source-file="PromoCard.jsx">
        <div className="ticket-content-wrapper">
          <span className="font-mono text-xl font-semibold md:text-3xl">
            {couponCode}
          </span>
          <span className="text-sm ticket-copy">
            {t('common.actions.click_to_copy')}
          </span>
        </div>
      </div>;
  };
  const gridClasses = cn({
    'grid gap-3 md:gap-6 md:max-w-[90%] justify-center mx-auto': true,
    'grid-cols-1 md:grid-cols-2': couponCode,
    'grid-cols-1': !couponCode
  });
  if (!enable) {
    return null;
  }
  return <section className={classes} data-sentry-component="PromoCard" data-sentry-source-file="PromoCard.jsx">
      <div className={`promo-card promo-card-${themeColor}`}>
        <div className="flex flex-col items-center justify-center gap-3">
          <Badge icon="BadgePercent" variant="light" href={ROUTES.PROMO} label={t('promo.card.badge')} data-sentry-element="Badge" data-sentry-source-file="PromoCard.jsx" />

          <h3 className="m-0 text-xl text-center md:text-4xl">
            {t('promo.card.title')}
          </h3>
        </div>

        {couponCode && <Ticket />}

        {SHOW_COUNTDOWN && <div className="flex justify-center">
            <CountdownDate endDate={PROMO_DATE} />
          </div>}

        <div className={gridClasses}>
          {couponCode && <div className="flex items-start gap-4">
              <Icon name="Tag" size="md" className="shrink-0 mt-[0.1rem]" />
              <h4 className="m-0 text-lg font-light text-balance">
                {t('promo.card.discount')}
              </h4>
            </div>}

          <div className="flex items-start gap-4">
            <Icon name="Clock" size="md" className="shrink-0 mt-[0.1rem]" data-sentry-element="Icon" data-sentry-source-file="PromoCard.jsx" />
            <h4 className="m-0 text-lg font-light text-balance">
              {t('promo.card.time', {
              date: dayjs(PROMO_DATE).format(getLongDateFormat(locale, false))
            })}
            </h4>
          </div>
        </div>

        <div className="flex justify-center">
          <Button variant={themeColor} icon="ArrowRight" className="md:min-w-[200px]" href={ROUTES.PROMO} label={t('promo.card.button')} data-sentry-element="Button" data-sentry-source-file="PromoCard.jsx" />
        </div>
      </div>
    </section>;
};
export default PromoCard;