import Badge from '@/components/ui/Badge';
import { COUNTERS } from '@/data';
import { useTranslations } from '@/hooks';
import { cn, formatNumber } from '@/utils';
export const HomeHeroBadges = ({
  className
}) => {
  const classes = cn('flex flex-col flex-wrap items-start justify-start gap-2 sm:flex-row', className);
  const t = useTranslations();
  return <div className={classes} data-sentry-component="HomeHeroBadges" data-sentry-source-file="HomeHeroBadges.jsx">
      <Badge size="md" variant="white-light" icon="ArrowLeftRight" value={t('home.solution.submissions.counter')} label={formatNumber(COUNTERS.SUBMISSIONS)} data-sentry-element="Badge" data-sentry-source-file="HomeHeroBadges.jsx" />

      <Badge size="md" variant="white-light" icon="UserCheck" value={t('home.solution.artists.counter')} label={formatNumber(COUNTERS.ARTISTS)} data-sentry-element="Badge" data-sentry-source-file="HomeHeroBadges.jsx" />

      <Badge size="md" variant="white-light" icon="ListMusic" value={t('home.solution.playlists.counter')} label={formatNumber(COUNTERS.PLAYLISTS)} data-sentry-element="Badge" data-sentry-source-file="HomeHeroBadges.jsx" />
    </div>;
};
export default HomeHeroBadges;