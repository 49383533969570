'use client';

import { motion, AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { cn } from '@/utils';
export const AnimatedBgFade = ({
  images,
  interval = 8000,
  transitionDuration = 1.5,
  className = '',
  shuffle = true,
  overlay = false,
  overlayClasses = '',
  noiseEffect = false
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [backgroundImages, setBackgroundImages] = useState(images);
  useEffect(() => {
    if (shuffle) {
      const shuffledImages = [...images].sort(() => Math.random() - 0.5);
      setBackgroundImages(shuffledImages);
    }
  }, [images, shuffle]);
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex(prev => (prev + 1) % backgroundImages.length);
    }, interval);
    return () => clearInterval(timer);
  }, [interval, backgroundImages.length]);
  return <div className={cn(className)} data-sentry-component="AnimatedBgFade" data-sentry-source-file="AnimatedBgFade.jsx">
      <AnimatePresence mode="sync" data-sentry-element="AnimatePresence" data-sentry-source-file="AnimatedBgFade.jsx">
        <motion.div key={currentImageIndex} className="absolute inset-0" initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} exit={{
        opacity: 0
      }} transition={{
        duration: transitionDuration,
        ease: 'easeInOut'
      }} data-sentry-element="unknown" data-sentry-source-file="AnimatedBgFade.jsx">
          <img src={backgroundImages[currentImageIndex]} alt="Background" className="object-cover w-full h-full" loading="eager" />
        </motion.div>
      </AnimatePresence>

      {overlay && <div className={cn('absolute inset-0', overlayClasses)} />}

      {noiseEffect && <div className="absolute inset-0 w-full h-full opacity-50 noise-bg" />}
    </div>;
};
export default AnimatedBgFade;