'use client';

import React, { useEffect } from 'react';
import { Swiper } from 'swiper';
import { Pagination } from 'swiper/modules';
import Badge from '@/components/ui/Badge';
import SectionTitle from '@/components/ui/SectionTitle';
import Video from '@/components/ui/Video';
import { useTranslations } from '@/hooks';
import { cn, shuffle } from '@/utils';
const Artists = ({
  className
}) => {
  useEffect(() => {
    new Swiper('.slider-artists', {
      modules: [Pagination],
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      spaceBetween: 0,
      breakpoints: {
        1280: {
          slidesPerView: 3.5
        },
        992: {
          slidesPerView: 2.5
        },
        640: {
          slidesPerView: 1.2
        }
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false
      }
    });
  }, []);
  const artists = [{
    image: '/images/landing/artists/artist_andrea_peligro.jpeg',
    link: 'https://open.spotify.com/artist/7DfF7VDJV8zeNCOl9sgjAX',
    name: 'Andrea Peligro',
    video: '/images/landing/artists/matchfy_andrea_peligro.mp4',
    quote: `It is a breath of fresh air, I finally felt safe trusting an agency that <b>truly knew how to promote music</b> on such a crucial platform for musicians`,
    language: 'it'
  }, {
    image: '/images/landing/artists/artist_rod_grand.jpeg',
    link: 'https://open.spotify.com/user/216tdww2q37nrymcjvvkr5efi',
    name: 'Rodrigo Granzella',
    video: '/images/landing/artists/matchfy_rodrigo_granzella.mp4',
    quote: `Without the playlists that are the modern radio, the would be no way to reach today as independent artists. The artist can <b>manage himself without having to go through third parties</b> with organic playlists, without bots that end up sinking them.`,
    language: 'es'
  }, {
    image: '/images/landing/artists/artist_dario_corso.jpeg',
    link: 'https://open.spotify.com/artist/2UKPaa2TFpjhsquHhmT4wS',
    name: 'Dario Corso',
    video: '/images/landing/artists/matchfy_dario_corso.mp4',
    quote: `I promote myself in two different ways: first I submit my songs to the various curators on the platform, second <b>I've become a curator myself</b>. It's awesome, I highly recommend it.`,
    language: 'it'
  }, {
    image: '/images/landing/artists/artist_jaituin.jpeg',
    link: 'https://open.spotify.com/artist/1EDraa7maAVwVRFByMNNBZ',
    name: 'Jaituin',
    video: '/images/landing/artists/matchfy_jaituin.mp4',
    quote: `<b>Shout out to Paolo Mantini, shout out to Alessia and everybody on the team</b>. I appreciate you for the love and support you guys have been giving me and the independent upcoming artists`,
    language: 'en'
  }, {
    image: '/images/landing/artists/artist_tommaso_scalici.jpeg',
    link: 'https://open.spotify.com/intl-it/artist/6KswMpmmtp3tUbWF3TjBgD',
    name: 'Tommaso Scalici',
    video: '/images/landing/artists/matchfy_tommaso_scalici.mp4',
    quote: `I find this platform amazing because <b>it's community based and artist-curator interaction</b>. It's easy to reach out to curators and get your tracks on their playlists.`,
    language: 'it'
  }, {
    image: '/images/landing/artists/artist_phatfunk_clique.jpeg',
    link: 'https://open.spotify.com/intl-it/artist/6WFRo8ZfzZwINeTHSzTyGS',
    name: 'Darrell Looney',
    video: '/images/landing/artists/matchfy_darrell_looney.mp4',
    quote: `The way the platform is set up, it might make you think that nobody digs your music what you do. Nothing's further from the truth. <b>There are hundreds of playlist curators that are into what you're creating</b>.`,
    language: 'us'
  }, {
    image: '/images/landing/artists/artist_gd_records.jpeg',
    link: 'https://open.spotify.com/intl-it/artist/1cYa6mIU9eGDgoTHwIqiUM',
    name: 'GD Records',
    video: '/images/landing/artists/matchfy_gd_records.mp4',
    quote: `Matchfy offers radio, TikTok, Instagram promos, but the real gem is their playlists for every genre. Thanks to these playlists, with real listeners,<b>you can hit Spotify's organic playlists</b>, which is the core of the streaming service.`,
    language: 'it'
  }];
  const t = useTranslations();
  const classes = cn({
    artists: true,
    section: true,
    container: true,
    [className]: className
  });
  const getLanguageFlag = language => {
    switch (language) {
      case 'it':
        return '🇮🇹';
      case 'en':
        return '🇬🇧';
      case 'es':
        return '🇪🇸';
      case 'us':
        return '🇺🇸';
      default:
        return '🌐';
    }
  };
  return <section id="artists" className={classes} data-sentry-component="Artists" data-sentry-source-file="Artists.jsx">
      <SectionTitle tag="h3" title={t('home.trusted_by.title')} subtitle={t('home.trusted_by.subtitle')} badge={<Badge variant="primary-light" icon="Star" label={t('navigation.artists')} />} data-sentry-element="SectionTitle" data-sentry-source-file="Artists.jsx" />

      <div className="slider slider-artists">
        <div className="swiper-wrapper">
          {shuffle(artists).map((artist, index) => <div className="swiper-slide" key={index}>
              <div className="artist-quote">
                {artist?.video && <div className="artist-video">
                    <Video className="shadow-none rounded-xl aspect-[9/16] h-full" src={artist?.video} />
                  </div>}

                <div className="flex flex-col justify-between h-full">
                  <q dangerouslySetInnerHTML={{
                __html: artist.quote
              }} />

                  <a href={artist.link} target="_blank" rel="noopener noreferrer">
                    <div className="artist-info">
                      <div className="artist-image">
                        <img src={artist.image} alt={artist.name} />
                      </div>
                      <h5>{artist.name}</h5>
                      <span className="md:text-xl">
                        {getLanguageFlag(artist.language)}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>)}
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </section>;
};
export default Artists;