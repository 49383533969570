import Icon from '@/components/ui/Icon';
import { PLAN_CONFIG } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const PlansMockup = ({
  className
}) => {
  const t = useTranslations();
  const classes = cn({
    'plans-mockup': true,
    'flex flex-col gap-4': true,
    'iso-right': true,
    [className]: className
  });
  const cardClasses = cn({
    'flex flex-col items-start gap-2': true,
    'bg-primary p-4 rounded-lg border-outline': true,
    'transform transition-all duration-300 hover:-translate-x-8': true,
    'shadow-md hover:shadow-lg': true
  });
  return <div className={classes} data-sentry-component="PlansMockup" data-sentry-source-file="PlansMockup.jsx">
      <div className={`${cardClasses} opacity-50 grayscale cursor-not-allowed`}>
        <h3 className={`inline-flex items-center gap-2 m-0 text-${PLAN_CONFIG.free.color}`}>
          <Icon className="text-xl" name={PLAN_CONFIG.free.icon} data-sentry-element="Icon" data-sentry-source-file="PlansMockup.jsx" />
          {PLAN_CONFIG.free.title}
        </h3>

        <p className="m-0 font-light opacity-20 text-balance">
          {t(`plans.card.types.${PLAN_CONFIG.free.slug}.caption`)}
        </p>
      </div>

      <div className={cardClasses}>
        <h3 className={`inline-flex items-center gap-2 m-0 text-${PLAN_CONFIG.artist.color}`}>
          <Icon className="text-xl" name={PLAN_CONFIG.artist.icon} data-sentry-element="Icon" data-sentry-source-file="PlansMockup.jsx" />
          {PLAN_CONFIG.artist.title}
        </h3>

        <p className="m-0 font-light opacity-80 text-balance">
          {t(`plans.card.types.${PLAN_CONFIG.artist.slug}.caption`)}
        </p>
      </div>

      <div className={cardClasses}>
        <h3 className={`inline-flex items-center gap-2 m-0 text-${PLAN_CONFIG.agency.color}`}>
          <Icon className="text-xl" name={PLAN_CONFIG.agency.icon} data-sentry-element="Icon" data-sentry-source-file="PlansMockup.jsx" />
          {PLAN_CONFIG.agency.title}
        </h3>

        <p className="m-0 font-light opacity-80 text-balance">
          {t(`plans.card.types.${PLAN_CONFIG.agency.slug}.caption`)}
        </p>
      </div>
    </div>;
};
export default PlansMockup;