'use client';

import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { cn } from '@/utils';
export const Video = ({
  ref,
  src,
  autoPlay,
  className,
  ...props
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    setIsPlaying(autoPlay);
  }, [autoPlay]);
  const classes = cn({
    video: true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="Video" data-sentry-source-file="Video.jsx">
      <ReactPlayer ref={ref} url={src} playing={isPlaying} width="100%" height="100%" controls {...props} data-sentry-element="ReactPlayer" data-sentry-source-file="Video.jsx" />
    </div>;
};
export default Video;