import React, { useContext } from 'react';
import { BentoCard, BentoGrid } from './BentoGrid';
import Badge from '@/components/ui/Badge';
import { DeviceMockup } from '@/components/ui/Mockup';
import SectionTitle from '@/components/ui/SectionTitle';
import { ThemeContext } from '@/context/ThemeProvider';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const FeaturesGrid = ({
  showTitle = false,
  className
}) => {
  const {
    currentTheme
  } = useContext(ThemeContext);
  const t = useTranslations();
  const classes = cn({
    features: true,
    section: true,
    container: true,
    [className]: className
  });
  return <section id="features" className={classes} data-sentry-component="FeaturesGrid" data-sentry-source-file="FeaturesGrid.jsx">
      {showTitle && <SectionTitle tag="h3" title="Your all-in-one music promotion platform" subtitle="What can you do with Matchfy?" badge={<Badge size="md" variant="orange-light" icon="AudioLines" label="Features" />} />}

      <BentoGrid className="md:max-w-[1200px] auto-rows-[420px] mx-auto" data-sentry-element="BentoGrid" data-sentry-source-file="FeaturesGrid.jsx">
        <BentoCard animateDirection="up" animateDelay={0.2} badge={<Badge variant="white" icon="Music" label="Spotify" />} badgePosition="top-left" bgBlur={true} bgOverlay={true} bgOverlayOpacity={20} className="border-outline" description="Matchfy connects your track with millions of playlists, using AI-powered targeting to increase your chances of discovery" href={ROUTES.MATCH} image="/images/landing/spotify_playlist_grid_iso.png" imagePosition="top" size="md" title="Spotify Playlist Pitch" data-sentry-element="BentoCard" data-sentry-source-file="FeaturesGrid.jsx" />

        <BentoCard animateDirection="up" animateDelay={0.4} badge={<Badge variant="light" icon="ShoppingBag" label="Paid Plan" />} badgePosition="top-left" className="p-4 border-outline" contentClasses="h-full items-start justify-end p-0" description="Get your music featured where it matters—from TikTok trends to music magazines and radio stations." href={ROUTES.TIKTOK} mockupClasses="relative overflow-hidden h-full rounded-md" mockup={<DeviceMockup className="absolute w-[80%] top-[-100%] left-[20%] iso-right" image="/images/vip/matchfy_tiktok_screen.jpg" alt="VIP Campaign TikTok Analytics" />} size="sm" title="TikTok, Magazines and Radio" data-sentry-element="BentoCard" data-sentry-source-file="FeaturesGrid.jsx" />

        <BentoCard animateDirection="right" animateDelay={0.6} badge={<Badge variant="light" icon="Gem" label="VIP" />} badgePosition="top-left" className="p-4 border-outline" contentClasses="h-full items-start justify-end p-0" description="Promote your music with targeted YouTube Ads that reach the right audience." href={`${ROUTES.VIP}?type=youtube`} mockupClasses="relative overflow-hidden h-full rounded-md" mockup={<DeviceMockup className="absolute w-[80%] top-[-100%] left-[20%] iso-right" image="/images/vip/matchfy_youtube_screen.jpg" alt="VIP Campaign YouTube Analytics" />} size="sm" title="YouTube Ads" data-sentry-element="BentoCard" data-sentry-source-file="FeaturesGrid.jsx" />

        <BentoCard animateDirection="bottom" animateDelay={0.8} bgBlur={true} bgOverlay={true} bgOverlayOpacity={20} badge={<Badge variant="light" icon="Gem" label="VIP" />} badgePosition="top-left" href={`${ROUTES.VIP}?type=tiktok`} className="border-outline" description="Boost your track with hyper-targeted TikTok Ads that put your music in front of the right audience." image="/images/vip/matchfy_tiktok_mockup.jpg" size="sm" title="TikTok Ads" data-sentry-element="BentoCard" data-sentry-source-file="FeaturesGrid.jsx" />

        <BentoCard animateDirection="left" animateDelay={1} badge={<Badge variant="light" icon="ShoppingBag" label="Paid Plan" />} badgePosition="top-left" className="border-outline" description="Distribute your music to all major stores and digital platforms with no extra costs." href={ROUTES.DISTRIBUTION} image={`/images/landing/matchfy_distro_mockup_${currentTheme}.jpg`} size="sm" title="Distribute" data-sentry-element="BentoCard" data-sentry-source-file="FeaturesGrid.jsx" />
      </BentoGrid>
    </section>;
};
export default FeaturesGrid;