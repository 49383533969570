'use client';

import { motion } from "motion/react";
import { useInView } from "react-intersection-observer";
import { Link } from "@/i18n/routing";
import { cn } from "@/utils";
export function BentoCard({
  title,
  description,
  image,
  className,
  children,
  imagePosition = "top",
  bgBlur = false,
  bgOverlay = false,
  bgOverlayOpacity = 50,
  bgPosition = "center",
  contentClasses,
  imageClasses,
  imageWrapperClasses,
  overlayClasses,
  size = "medium",
  mockup,
  mockupClasses,
  mockupPosition = "top",
  animateDirection = "up",
  animateDelay,
  href,
  badge,
  badgePosition = "top-right",
  badgeClasses,
  ...props
}) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: "0px 0px -10% 0px"
  });
  const contentCn = cn("relative z-10 flex flex-col gap-1 px-4", imagePosition === 'top' && "items-start justify-end pb-4", imagePosition === 'bottom' && "items-start justify-start pt-4", imagePosition === 'background' && "py-4", contentClasses);
  const textCn = cn({
    "text-balance": true,
    "text-white": bgBlur
  });
  const sizeClasses = {
    sm: "col-span-1 md:col-span-1",
    md: "col-span-1 md:col-span-2",
    lg: "col-span-1 md:col-span-3"
  };
  const getAnimationVariants = () => {
    const directions = {
      up: {
        y: 30,
        x: 0
      },
      down: {
        y: -30,
        x: 0
      },
      left: {
        x: 30,
        y: 0
      },
      right: {
        x: -30,
        y: 0
      },
      none: {
        y: 0,
        x: 0
      }
    };
    const direction = directions[animateDirection] || directions.up;
    return {
      hidden: {
        opacity: 0,
        scale: 0.95,
        ...direction
      },
      visible: {
        opacity: 1,
        scale: 1,
        y: 0,
        x: 0,
        transition: {
          duration: 0.35,
          delay: animateDelay,
          ease: [0.215, 0.61, 0.355, 1]
        }
      }
    };
  };
  const renderMockup = () => {
    if (!mockup) return null;
    const mockupCn = cn({
      [mockupClasses]: mockupClasses
    });
    return <div className={mockupCn} data-sentry-component="renderMockup" data-sentry-source-file="BentoGrid.jsx">
        {mockup}
      </div>;
  };
  const renderImage = position => {
    if (!image) return null;
    const imageWrapperCn = {
      top: "overflow-hidden p-4 z-10",
      bottom: "overflow-hidden p-4 z-10",
      background: "absolute inset-0 z-10"
    };
    const imageCn = {
      top: "object-cover w-full h-full rounded-md",
      bottom: "inset-0 object-cover w-full h-full rounded-md",
      background: "object-cover w-full h-full rounded-md"
    };
    return <div className={cn("relative w-full h-full", imageWrapperCn[position], imageWrapperClasses)} data-sentry-component="renderImage" data-sentry-source-file="BentoGrid.jsx">
        <img src={image} alt={title} className={cn(imageCn[position], `object-${bgPosition}`, imageClasses)} />
      </div>;
  };
  const renderBadge = () => {
    if (!badge) return null;
    const positionClasses = {
      'top-left': 'top-6 left-6',
      'top-right': 'top-6 right-6',
      'bottom-left': 'bottom-6 left-6',
      'bottom-right': 'bottom-6 right-6'
    };
    return <div className={cn("absolute z-20", positionClasses[badgePosition] || positionClasses['top-right'], badgeClasses)} data-sentry-component="renderBadge" data-sentry-source-file="BentoGrid.jsx">
        {badge}
      </div>;
  };
  const cardContent = <>
      {renderBadge()}
      {imagePosition === "background" && renderImage("background")}
      {mockup && mockupPosition === "top" && renderMockup("top")}

      {bgBlur && image && <div className="absolute inset-0 scale-150 blur-lg z-1">
          {renderImage("background")}
        </div>}

      <div className={cn("relative z-10 flex flex-col h-full flex-1 justify-between")}>
        {imagePosition === "top" && renderImage("top")}

        <div className={contentCn}>
          <h3 className={cn("text-xl font-semibold md:text-2xl text-balance max-w-[18ch] m-0", textCn)}>
            {title}
          </h3>

          {description && <p className={cn("text-sm md:text-base text-balance", textCn)}>
              {description}
            </p>}

          {children}
        </div>

        {imagePosition === "bottom" && renderImage("bottom")}
        {mockup && mockupPosition === "bottom" && renderMockup("bottom")}

        {bgOverlay && <div className={cn("absolute inset-0 overlay z-1", `opacity-${bgOverlayOpacity}`, overlayClasses)} />}
      </div>
    </>;
  const classes = cn("relative flex flex-col gap-3 overflow-hidden rounded-2xl bg-card", sizeClasses[size], className);
  const motionProps = {
    ref,
    initial: "hidden",
    animate: inView ? "visible" : "hidden",
    variants: getAnimationVariants(),
    whileHover: {
      scale: 0.98,
      transition: {
        duration: 0.5,
        ease: [0.215, 0.61, 0.355, 1]
      }
    }
  };
  if (href) {
    return <motion.div {...motionProps} className={classes} {...props}>
        <Link href={href} className="flex flex-col w-full h-full gap-3">
          {cardContent}
        </Link>
      </motion.div>;
  }
  return <motion.div {...motionProps} className={classes} {...props} data-sentry-element="unknown" data-sentry-component="BentoCard" data-sentry-source-file="BentoGrid.jsx">
      {cardContent}
    </motion.div>;
}
export function BentoGrid({
  className,
  children
}) {
  return <div className={cn("grid md:grid-cols-[1fr_0.8fr_1fr] gap-4", className)} data-sentry-component="BentoGrid" data-sentry-source-file="BentoGrid.jsx">
      {children}
    </div>;
}