import Icon from '@/components/ui/Icon';
import { LogoIcon } from '@/components/ui/Logo/Logo';
import { USER_ROYALTIES_PERCENTAGE } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const RoyaltiesMockup = ({
  className
}) => {
  const t = useTranslations();
  const classes = cn({
    'royalties-mockup': true,
    'flex flex-col items-start gap-2 w-full': true,
    [className]: className
  });
  const cardClasses = cn({
    'flex flex-col items-start gap-2': true,
    'bg-primary p-4 rounded-lg border-outline': true,
    'transform transition-all duration-300': true,
    'scale-100 hover:scale-95': true,
    'shadow-md hover:shadow-lg': true
  });
  return <div className={classes} data-sentry-component="RoyaltiesMockup" data-sentry-source-file="RoyaltiesMockup.jsx">
      <div className={`${cardClasses} w-full`}>
        <h3 className="inline-flex items-center gap-4 m-0 text-green">
          <Icon name="UserRound" className="text-3xl" data-sentry-element="Icon" data-sentry-source-file="RoyaltiesMockup.jsx" />
          <span className="text-4xl">
            {(USER_ROYALTIES_PERCENTAGE * 100).toFixed(0)}%
          </span>
        </h3>

        <p className="m-0 font-light opacity-80 text-balance">
          {t('royalties.user.caption')}
        </p>
      </div>

      <div className={`${cardClasses} w-fit`}>
        <h3 className="inline-flex items-center gap-4 m-0 text-primary">
          <LogoIcon className="w-10 h-10" data-sentry-element="LogoIcon" data-sentry-source-file="RoyaltiesMockup.jsx" />
          <span className="text-4xl">
            {((1 - USER_ROYALTIES_PERCENTAGE) * 100).toFixed(0)}%
          </span>
        </h3>

        <p className="m-0 font-light opacity-80 text-balance">
          {t('royalties.matchfy.caption')}
        </p>
      </div>
    </div>;
};
export default RoyaltiesMockup;