'use client';

import { motion } from "motion/react";
import dynamic from 'next/dynamic';
import React from 'react';
import Auth from '@/components/Auth';
import { AnimatedBgFade, Collabs, HomeHeroBadges } from '@/components/Landing';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import Separator from '@/components/ui/Separator';
import { TrustpilotMicroComboWidget } from '@/components/ui/Trustpilot';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const BeamBackground = dynamic(() => import('./BeamBackground'), {
  ssr: false
});
export const HomeHero = ({
  className
}) => {
  const t = useTranslations();
  const backgroundImages = Array.from({
    length: 10
  }, (_, i) => `/images/backgrounds/matchfy_bg_${i + 1}.jpg`);
  return <div className={cn("home-hero relative w-full overflow-hidden", className)} data-sentry-component="HomeHero" data-sentry-source-file="HomeHero.jsx">
      <BeamBackground colors={['#5e4af9', '#225CF4', '#2196f3', '#2196f3']} className="z-20" overlayClasses="opacity-10" data-sentry-element="BeamBackground" data-sentry-source-file="HomeHero.jsx" />

      <div className="relative z-20 flex items-center justify-center w-full h-full">
        <div className="container">
          <div className="flex flex-col gap-8 md:grid lg:grid-cols-3">
            <div className="flex flex-col items-start justify-center gap-6 pt-32 pb-24 text-left md:pt-40 md:col-span-2">
              <motion.h1 className="m-0 text-4xl font-semibold text-white sm:text-5xl md:text-6xl text-balance" initial={{
              opacity: 0,
              y: 20
            }} animate={{
              opacity: 1,
              y: 0
            }} transition={{
              duration: 0.8
            }} data-sentry-element="unknown" data-sentry-source-file="HomeHero.jsx">
                {t('home.main.title')}
              </motion.h1>

              <motion.p className="m-0 text-lg tracking-tighter text-white md:text-2xl opacity-90 text-balance" initial={{
              opacity: 0,
              y: 20
            }} animate={{
              opacity: 1,
              y: 0
            }} transition={{
              duration: 0.8
            }} data-sentry-element="unknown" data-sentry-source-file="HomeHero.jsx">
                {t('home.main.subtitle')}
              </motion.p>

              <Auth login loginProps={{
              label: t('home.main.start_now'),
              className: 'mt-2',
              variant: 'home',
              size: 'lg'
            }} data-sentry-element="Auth" data-sentry-source-file="HomeHero.jsx">
                <Button href={ROUTES.MATCH} size="lg" variant="home" className="mt-2 font-xl" label={t('home.main.start_now')} data-sentry-element="Button" data-sentry-source-file="HomeHero.jsx" />
              </Auth>

              <a href="#intro" className="mt-3 text-white opacity-70">
                <Icon name="ArrowDown" className="mr-2" data-sentry-element="Icon" data-sentry-source-file="HomeHero.jsx" />
                {t('home.main.scroll_down')}
              </a>

              <HomeHeroBadges data-sentry-element="HomeHeroBadges" data-sentry-source-file="HomeHero.jsx" />

              <TrustpilotMicroComboWidget className="w-full mt-4" data-sentry-element="TrustpilotMicroComboWidget" data-sentry-source-file="HomeHero.jsx" />

              <Separator className="w-full my-3 bg-white opacity-30" data-sentry-element="Separator" data-sentry-source-file="HomeHero.jsx" />

              <Collabs show={true} className="text-white" data-sentry-element="Collabs" data-sentry-source-file="HomeHero.jsx" />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-0 z-10">
        <AnimatedBgFade images={backgroundImages} className="z-10" interval={60 * 60 * 1000} noiseEffect={true} overlayClasses="overlay-gradient-h overlay-clip-home" overlay={true} shuffle={true} transitionDuration={1} data-sentry-element="AnimatedBgFade" data-sentry-source-file="HomeHero.jsx" />
      </div>
    </div>;
};
export default HomeHero;