import { AppleMusicLogo, BeatportLogo, DeezerLogo, NapsterLogo, ShazamLogo, SoundcloudLogo, SpotifyLogo, TidalLogo, YouTubeLogo } from '@/components/ui/Logo';
import { cn } from '@/utils';
const DSPsLogosMockup = ({
  className
}) => {
  const dsps = [{
    key: 'apple',
    component: AppleMusicLogo
  }, {
    key: 'beatport',
    component: BeatportLogo
  }, {
    key: 'deezer',
    component: DeezerLogo
  }, {
    key: 'napster',
    component: NapsterLogo
  }, {
    key: 'shazam',
    component: ShazamLogo
  }, {
    key: 'soundcloud',
    component: SoundcloudLogo
  }, {
    key: 'spotify',
    component: SpotifyLogo
  }, {
    key: 'tidal',
    component: TidalLogo
  }, {
    key: 'youtube',
    component: YouTubeLogo
  }];
  const classes = cn({
    'dsps-logos-mockup': true,
    'flex flex-row flex-wrap items-start gap-2 w-full': true,
    [className]: className
  });
  const cardClasses = cn({
    'flex flex-col items-start gap-2': true,
    'bg-primary p-4 rounded-lg border-outline': true,
    'transform transition-all duration-300': true,
    'scale-100 hover:scale-95': true,
    'shadow-md hover:shadow-lg': true
  });
  return <div className={classes} data-sentry-component="DSPsLogosMockup" data-sentry-source-file="DSPsLogosMockup.jsx">
      {dsps.map(dsp => <div key={dsp.key} className={cardClasses}>
          <dsp.component className="w-auto h-6" />
        </div>)}
    </div>;
};
export default DSPsLogosMockup;