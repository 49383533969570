import { cn } from '@/utils';
const Mockup = ({
  address,
  children,
  favicon,
  placeholder,
  showAddressBar,
  enableLink,
  className
}) => {
  const addressBarURL = address ? address : '';
  const classes = cn({
    mockup: true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="Mockup" data-sentry-source-file="Mockup.jsx">
      {showAddressBar && <div className="header">
          <div className="addressbar">
            {favicon && <div className="favicon">
                <img src={favicon} alt="favicon" />
              </div>}
            {enableLink && <div className="address">
                <a href={addressBarURL} target="_blank" rel="noopener noreferrer">
                  {addressBarURL}
                </a>
              </div>}

            {!enableLink && !placeholder && <div className="address">{addressBarURL}</div>}

            {placeholder && <div className="address">
                <div className="address-placeholder">{placeholder}</div>
              </div>}
          </div>
        </div>}
      {children}
    </div>;
};
export default Mockup;